@tailwind base;
@tailwind components;
@tailwind utilities;


.montserrat-light {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .montserrat-regular {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .montserrat-medium {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .montserrat-semibold {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .montserrat-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  * {
    font-family: "Montserrat", sans-serif;
  }

  @keyframes gradientShift {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}




  .icon-container {
    position: relative;
    display: inline-block;
  }

  .icon-container::after {
    content: '';
    position: absolute;
    bottom: -2px; /* Adjust based on how close you want the line to be to the icon */
    left: 0;
    width: 0;
    height: 2px; /* Adjust the thickness of the underline */
    background-color: black; /* Adjust the color of the underline */
    transition: width 0.3s ease; /* Adjust the speed of the underline animation */
  }
  .icon-container:hover::after {
    width: 100%;
  }


  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes fadeInAccelerate {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  